import { ResponseData } from './InterestCheck';

export interface BasicInfoFormat {
  // institution_sub: any | undefined;
  user_id: any;
  user_name: any | undefined;
  user_name_kana: any | undefined;
  user_gender: any | undefined;
  user_birth_year: number | undefined;
  user_birth_month: any | undefined;
  user_birth_day: any | undefined;
  user_care_level: any | undefined;
  user_care_id: any | undefined;
  user_visit_day: any[] | undefined;
  // 以下 basicinfoにしかない要素
  planner: any | undefined;
  planner_job: any | undefined;
  program_planner: any | undefined;
  program_planner_job: any | undefined;
  independence_level_disabled_elderly: any | undefined;
  independence_level_dementia_elderly: any | undefined;
  user_wish: any | undefined;
  family_wish: any | undefined;
  disease_name: any | undefined;
  idc10_disease_name: any | undefined;
  illness_era: any | undefined;
  illness_year: any | undefined;
  illness_month: any | undefined;
  illness_day: any | undefined;
  hospitalization_era: any | undefined;
  hospitalization_year: any | undefined;
  hospitalization_month: any | undefined;
  hospitalization_day: any | undefined;
  discharge_era: any | undefined;
  discharge_year: any | undefined;
  discharge_month: any | undefined;
  discharge_day: any | undefined;
  course_of_treatment: any | undefined;
  complicated_diseases_1: any | undefined;
  complicated_diseases_icd10_1: any | undefined;
  complicated_diseases_2: any | undefined;
  complicated_diseases_icd10_2: any | undefined;
  complicated_diseases_3: any | undefined;
  complicated_diseases_icd10_3: any | undefined;
  complicated_diseases_4: any | undefined;
  complicated_diseases_icd10_4: any | undefined;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface ICD10 {
  name: string;
  icd10: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface ICF {
  goal: string;
  icf: string;
  item: string;
}

export interface Support {
  program: string;
  code: string;
  item: string;
}

export interface PlanFormat {
  plan_id: any | undefined;
  user_id: any | undefined;
  created_at: any | undefined;
  updated_at: any | undefined;
  care_level: any | undefined;
  training: any | undefined;
  item: any | undefined;
  pattern: any | undefined;
  bundlemaster_key: any | undefined;
  note: any | undefined;
  short_goal_start_era: any | undefined;
  short_goal_start_year: any | undefined;
  short_goal_start_month: any | undefined;
  short_goal_finish_era: any | undefined;
  short_goal_finish_year: any | undefined;
  short_goal_finish_month: any | undefined;
  short_goal_achievement: any | undefined;
  short_goal_function: any | undefined;
  short_goal_function_icf: any | undefined;
  short_goal_activity: any | undefined;
  short_goal_activity_icf: any | undefined;
  short_goal_participation: any | undefined;
  short_goal_participation_icf: any | undefined;
  long_goal_start_era: any | undefined;
  long_goal_start_year: any | undefined;
  long_goal_start_month: any | undefined;
  long_goal_finish_era: any | undefined;
  long_goal_finish_year: any | undefined;
  long_goal_finish_month: any | undefined;
  long_goal_achievement: any | undefined;
  long_goal_activity: any | undefined;
  long_goal_activity_icf: any | undefined;
  long_goal_function: any | undefined;
  long_goal_function_icf: any | undefined;
  long_goal_participation: any | undefined;
  long_goal_participation_icf: any | undefined;
  program_1: any | undefined;
  program_support_1: any | undefined;
  program_note_1: any | undefined;
  program_frequency_1: any | undefined;
  program_time_1: any | undefined;
  program_practitioner_1: any | undefined;
  program_2: any | undefined;
  program_support_2: any | undefined;
  program_note_2: any | undefined;
  program_frequency_2: any | undefined;
  program_time_2: any | undefined;
  program_practitioner_2: any | undefined;
  program_3: any | undefined;
  program_support_3: any | undefined;
  program_note_3: any | undefined;
  program_frequency_3: any | undefined;
  program_time_3: any | undefined;
  program_practitioner_3: any | undefined;
  program_4: any | undefined;
  program_support_4: any | undefined;
  program_note_4: any | undefined;
  program_frequency_4: any | undefined;
  program_time_4: any | undefined;
  program_practitioner_4: any | undefined;
  program_planner: any | undefined;
  program_planner_job: any | undefined;
  outside_of_service: any | undefined;
  special_remarks: any | undefined;
  change: any | undefined;
  issue_factor: any | undefined;
  new: any | undefined;
  //TODO その他入力項目
  creation_year: any | undefined;
  creation_month: any | undefined;
  creation_day: any | undefined;
  last_creation_year: any | undefined;
  last_creation_month: any | undefined;
  last_creation_day: any | undefined;
  first_creation_year: any | undefined;
  first_creation_month: any | undefined;
  first_creation_day: any | undefined;
  social_participation: any | undefined;
  residence_environment: any | undefined;
}

export interface BundleMaster {
  id: string;
  care_level: string;
  training: string;
  item: string;
  pattern: string;
  short_goal_function: string;
  short_goal_function_icf: string;
  short_goal_activity: string;
  short_goal_activity_icf: string;
  short_goal_participation: string;
  short_goal_participation_icf: string;
  long_goal_function: string;
  long_goal_function_icf: string;
  long_goal_activity: string;
  long_goal_activity_icf: string;
  long_goal_participation: string;
  long_goal_participation_icf: string;
  program_1: string;
  program_support_1: string;
  program_note_1: string;
  program_time_1: string;
  program_practitioner_1: string;
  program_2: string;
  program_support_2: string;
  program_note_2: string;
  program_time_2: string;
  program_practitioner_2: string;
  program_3: string;
  program_support_3: string;
  program_note_3: string;
  program_time_3: string;
  program_practitioner_3: string;
  program_4: string;
  program_support_4: string;
  program_note_4: string;
  program_time_4: string;
  program_practitioner_4: string;
  outside_of_service: string;
  special_remarks: string;
}

// 1 要介護レベル
export const CARE_LEVELS: { [key: string]: string } = {
  // 0: '要支援１',
  // 1: '要支援２',
  2: '要介護１',
  3: '要介護２',
  4: '要介護３',
  5: '要介護４',
  6: '要介護５',
  // 999: '要介護なし',
};

// 施設.
export interface Institution {
  company_name: string;
  created_at: string;
  institution_email: string;
  institution_id: string;
  institution_name: string;
  institution_name_kana: string;
  institution_status: string;
  institution_sub: string;
  updated_at: string;
  // "iPadCode": string;
  application_year_month: string;
  address: string;
  company_name_kana: string;
  ContactPerson: string;
  post_code: string;
  // "enabled": string;
  Administrator: string;
  institution_phone: string;
  institution_care_id: string;
  fax: string;
}

export interface MonthlyUsersData {
  monthUsersRecords: {
    userId: string;
    data: ResponseData[];
  }[];
}

export const LOADING = 'FUNCTIONAL_TRAINING_PLAN_LOADING';
// エラーでもFINISH
export const FINISH = 'FUNCTIONAL_TRAINING_PLAN_FINISH';

export const SUCCESS = 'FUNCTIONAL_TRAINING_PLAN_SUCCESS';
export const ERROR = 'FUNCTIONAL_TRAINING_PLAN_ERROR';
